<template>
  <div>
    <div class="card">
      <ConfirmPopup style="z-index: 10000" :group="popupGroupName">
        <template #message>
          <div class="card shadow-0 border-0 p-3 mb-2">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="d-flex justify-content-between align-items-center mb-2"
                  v-if="tests.length != 0 || displayMode == 'mega-menu'"
                >
                  <h6>Eléments sélectionnés ({{ tests.length }})</h6>
                  <PButton
                    v-tooltip.top="`Réinitialiser la sélection`"
                    class="p-button-danger p-button-sm p-1 ms-1"
                    @click.prevent.stop="tests = []"
                  >
                    Vider
                  </PButton>
                </div>
                <div
                  class="mega-seletor-view"
                  v-if="displayMode == 'mega-menu'"
                  style="width: 450px"
                >
                  <div class="row">
                    <div class="col-md-6 col-sm-6" v-for="item of items" :key="item.id">
                      <div class="field-checkbox mb-2">
                        <PCheckbox
                          :id="'_item_' + item[optionValue] + '_' + item[optionLabel]"
                          name="item_"
                          :value="item"
                          v-model="tests"
                        />
                        <label
                          class="ms-2 cursor-pointer"
                          :for="'_item_' + item[optionValue] + '_' + item[optionLabel]"
                          >{{ item[optionLabel] }}</label
                        >
                      </div>
                      <!-- <PCheckbox
                            :id="'_item_'+i"
                            :inputId="'_item_'+i"
                            :name="'_item_'"
                            v-model="tests"
                            :value="item"
                            /> -->
                      <!-- {{ item[optionLabel] }} -->
                    </div>
                  </div>
                </div>
                <div class="classic-selector-view" v-if="displayMode == 'classic'">
                  <div
                    v-if="tests.length != 0"
                    class="mb-2 border border-dark-500 pt-2"
                    style="width: 320px"
                  >
                    <PTag
                      severity="warning"
                      class="ms-2 mb-2"
                      v-for="(item, i) in tests"
                      :key="i"
                    >
                      <span class="p-1 text-">
                        {{ item[optionLabel] }}
                        <PButton
                          v-tooltip.top="`Retirer de la sélection`"
                          icon="pi pi-times cursor-pointer"
                          class="p-button-sm ms-1 p-0"
                          @click.prevent.stop="
                            tests = tests.filter((el, index) => index != i)
                          "
                          style="height: 18px; width: 18px"
                        />
                      </span>
                    </PTag>
                  </div>
                  <PAutoComplete
                    class="popconfirm-autocomplete"
                    v-model="search"
                    placeholder="Saisir ici pour rechercher"
                    :suggestions="items"
                    @item-select="itemSelected"
                    @complete="asyncFind($event)"
                    :field="optionLabel"
                  >
                    <template #item="slotProps">
                      <div
                        class="input-item border-bottom d-flex justify-items-center"
                        style="width: 320px"
                      >
                        <!-- <PCheckbox
                            :id="'_item_'+slotProps.item[optionValue]"
                            :inputId="'_item_'+slotProps.item[optionValue]"
                            :name="'_item_'+slotProps.item[optionValue]"
                            :value="slotProps.item" v-model="tests" :binary="true" /> -->
                        <div class="text-wrap ms-2">
                          {{ slotProps.item[optionLabel] }}
                        </div>
                      </div>
                    </template>
                  </PAutoComplete>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ConfirmPopup>
      <!-- <div v-if="tests.length != 0" class="mb-2">
          <PTag severity="warning" class="ms-2 mt-1" v-for="(item, i) in tests" :key="i">
            <span class="p-1">
              {{ item[optionLabel] }}
              <PButton icon="pi pi-times-circle" class="p-button-text p-button-sm ms-1 p-0" @click.prevent="tests=tests.filter((el, index) => index != i )" style="height: 20px; width: 20px"  />
            </span>
          </PTag>
        </div> -->
      <PDropdown
        :options="[]"
        class="dropless"
        :placeholder="placeholder"
        @click.prevent.stop="showPopup($event)"
      >
        <template #option>
          <div></div>
        </template>
        <template #value>
          <div class="d-flex justify-content-between" @click.prevent="showPopup($event)">
            <div class="">
              {{ placeholder }}
              <span v-if="tests.length > 0" class=""
                ><i><PBadge :value="`${tests.length} sélectionné`"></PBadge></i
              ></span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <PButton
                v-if="tests.length > 0"
                icon="pi pi-times"
                v-tooltip.top="`Réinitialiser la sélection`"
                class="p-button-danger p-button-text px-0 p-button-sm p-0 me-1"
                @click.prevent.stop="unselectAll"
              />
              <i
                class="pi pi-chevron-down"
                style="font-size: 1rem"
                @click.prevent="showPopup($event)"
              />
            </div>
          </div>
        </template>
        <template #indicator>
          <span></span>
        </template>
      </PDropdown>

      <!-- <PButton @click.prevent="showPopup($event)" class="p-button-sm" icon="pi pi-check" label="Confirm"></PButton> -->
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { paginatorMixin } from "../../mixins/paginator-mixin";
import { get } from "../../api/api";
import ShortUniqueId from "short-unique-id";
import ConfirmPopup from "primevue/confirmpopup";

let uuid = new ShortUniqueId({
  length: 10,
});

export default {
  props: {
    value: {
      type: [String, Object, Array],
    },
    queryUrl: {
      type: String,
      default: "romes",
    },
    searchOptions: {
      type: Array,
      default: () => ["libelle"],
    },
    emitObject: {
      type: Boolean,
      default: true,
    },
    optionValue: {
      type: String,
      default: "id",
    },
    placeholder: {
      type: String,
      default: "",
    },
    optionLabel: {
      type: String,
      default: "",
    },
    popupGroupName: {
      type: String,
      default: () => uuid(),
    },
    displayMode: {
      type: String,
      default: "mega-menu",
      validator: (propValue) => ["classic", "mega-menu"].includes(propValue),
    },
    asyncDataFetch: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Multiselect,
    ConfirmPopup,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      tests: [],
      search: "",
      items: [...this.options],
      isLoading: false,
      // initialized: false,
      suggestions: [],
    };
  },
  created() {
    // if(this.displayMode == 'mega-menu'){
    // this.makeLazyQuery("");
    // }
  },
  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
    value: {
      handler(val) {
        if (val != null) this.tests = [...val];
      },
      immediate: true,
    },
  },
  computed: {
    selectedIds() {
      return this.tests.map((el) => el[this.optionValue]);
    },
    checkableItems() {
      return this.items.map((item) => ({
        checked: false,
        value: item,
      }));
    },
  },
  methods: {
    showPopup(event) {
      this.$confirm.require({
        group: this.popupGroupName,
        target: event.currentTarget,
        message: "L'activité a-t-elle pu tenir?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Appliquer",
        rejectLabel: "Fermer",
        accept: () => {
          this.$emit(
            "input",
            this.emitObject ? this.tests : this.tests.filter((el) => el[this.optionValue])
          );
        },
        reject: () => {},
      });
    },
    unselectAll() {
      this.tests = [];
    },
    itemSelected(item) {
      const exists =
        this.tests.filter((el) => el[this.optionValue] == item.value[this.optionValue])
          .length != 0;
      if (!exists) {
        this.tests.push(item.value);
      }
      this.search = "";
    },
    limitText(count) {
      return `et  ${count} autres éléments`;
    },
    itemCheckChanged(event, index) {},
    removeItem(item) {
      this.tests = this.tests.filter((el) => item != el.id);
    },
    asyncFind(event) {
      let query = event.query.trim();
      console.log(query);
      this.makeLazyQuery(query);
    },
    makeLazyQuery(query) {
      if (!this.asyncDataFetch) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        let queryArg = "";

        this.searchOptions.forEach(
          (opt) => (queryArg += opt + "=" + query.toUpperCase() + "&size=20")
        );
        get(`${this.queryUrl}?${queryArg}`).then(({ data }) => {
          // let results = (data instanceof Array? data : data.content).filter(item => !this.selectedIds.includes(item[this.optionValue]))
          this.items = data instanceof Array ? data : data.content;
          this.isLoading = false;
        });
      }, 500);
    },
    clearAll() {
      this.selected = [];
    },
    loadData(event) {
      // if(this.this.option.url == undefined) return this.suggestions = []

      let url = `${this.option.url}?${this.option.key}=${event.query}`;
      if (this.option.urlBuilder != null) {
        url = this.option.urlBuilder();
      }

      get(url).then(({ data }) => {
        this.suggestions = data instanceof Array ? data : data.content;
      });
    },
  },
};
</script>

<style>
.dropless .p-dropdown-panel .p-dropdown-items {
  background: transparent;
  border: none !important;
  opacity: 0;
  height: 0px !important;
  padding: 0px !important;
  /* display:!important;; */
}

.dropless .p-dropdown .p-dropdown-label.p-placeholder {
  background-color: pink !important;
  padding: 0px;
}

.dropless .p-dropdown-trigger {
  /* background-color: yellow; */
  display: none;
}

.popconfirm-autocomplete .p-autocomplete-input.p-inputtext.p-component {
  width: 320px;
}
</style>
