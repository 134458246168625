<template>
  <div>
    <p-auto-complete
      v-model="option.value"
      :placeholder="option.label"
      :dropdown="option.dropdown"
      :suggestions="suggestions"
      :disabled="disabled"
      v-bind="$attrs"
      v-on="{
        ...$listeners,
      }"
      @complete="loadData($event)"
      :field="option.key"
    />
  </div>
</template>

<script>
import { get } from "../../api/api";
export default {
  props: {
    value: {
      type: [String, Object, Array],
    },
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      suggestions: [],
    };
  },
  computed: {
    disabled() {
      if (this.option.enabled == null) return false;

      if (this.option.enabled instanceof Function) {
        return !this.option.enabled();
      }

      return !this.option.enabled;
    },
  },
  methods: {
    loadData(event) {
      // if(this.this.option.url == undefined) return this.suggestions = []

      let url = `${this.option.url}?${this.option.key}=${event.query}`;
      if (this.option.urlBuilder != null) {
        url = this.option.urlBuilder();
      }

      get(url).then(({ data }) => {
        this.suggestions = data instanceof Array ? data : data.content;
      });
    },
  },
};
</script>

<style></style>
